<template>
  <v-row>
    <snack-bar />
    <v-col
      cols="12"
      lg="7"
      xl="6"
      class="primary d-none d-md-flex align-center justify-center backgroundImg"
    >
      <v-container>
        <div class="pa-10">
          <v-row justify="center">
            <v-col cols="8" xl="5">
              <div class="d-flex justify-center">
                <img
                  src="@/assets/images/logos/logo-vumetric-telus-white.svg"
                  alt="Logo"
                >
              </div>
              <div class="d-flex justify-center">
                <v-btn
                  class="mt-4 text-capitalize"
                  x-large
                  outlined
                  color="white"
                >
                  Learn More
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-col>
    <v-col cols="12" lg="5" xl="6" class="d-flex align-center">
      <v-container>
        <div class="pa-7 pa-sm-12">
          <v-row>
            <v-col cols="12" lg="9" xl="6">
              <h2 class="font-weight-bold mt-4">
                Reset your password
              </h2>
              <h6 class="subtitle-1">Enter your new password and confirm</h6>

              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
              >
                <template v-if="result">
                  <v-card class="my-4 pa-4" color="grey" elevation="0">
                    <div>The password score is {{ result.score }}/4</div>
                    <v-progress-linear
                      v-if="password"
                      v-model="scoreProgress"
                      rounded
                      class="mb-4 mt-2"
                    />
                    <div class="mx-2 mt-2">
                      <div class="mb-2" v-if="result.feedback.warning"><b class="warning--text">Warning!</b>
                        <div>- {{ result.feedback.warning }}</div>
                      </div>
                      <div v-if="result.feedback.suggestions.length >= 1"><b class="primary--text text--lighten-2">Suggestions:</b>
                        <span
                          v-for="(suggestion, index) in result.feedback.suggestions"
                          :key="index"
                        >
                          <div> - {{ suggestion }}</div>
                        </span>
                      </div>
                    </div>
                  </v-card>
                </template>
                <v-text-field
                  v-model="password"
                  label="Password *"
                  :type="valuePW ? 'password' : 'text'"
                  :rules="passwordRules"
                  required
                  outlined
                  @click="infoPW = true"
                >
                  <v-icon
                    slot="append"
                    color="primary"
                    @click="valuePW = !valuePW"
                  >
                    {{ valuePW ? 'mdi-eye' : 'mdi-eye-off' }}
                  </v-icon>
                </v-text-field>
                <v-text-field
                  v-model="confirmPassword"
                  label="Confirm Password"
                  :type="valueCPW ? 'password' : 'text'"
                  :rules="confirmPasswordRules"
                  outlined
                >
                  <v-icon
                    slot="append"
                    color="primary"
                    @click="valueCPW = !valueCPW"
                  >
                    {{ valueCPW ? 'mdi-eye' : 'mdi-eye-off' }}
                  </v-icon>
                </v-text-field>
                <v-btn
                  color="primary"
                  :disabled="disableBtn()"
                  block
                  class="mr-4"
                  @click="resetPassword"
                >
                  Confirm
                </v-btn>
              </v-form>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-col>
  </v-row>
</template>
<script>
import AuthService from '@/services/auth.service'
import snackbarPlugin from '@/plugins/snackbar'
import SnackBar from '@/components/base/SnackBar'

import { zxcvbnAsync, zxcvbnOptions, debounce } from '@zxcvbn-ts/core'
import zxcvbnCommonPackage from '@zxcvbn-ts/language-common'
import zxcvbnEnPackage from '@zxcvbn-ts/language-en'
import matcherPwnedFactory from '@zxcvbn-ts/matcher-pwned'

const matcherPwned = matcherPwnedFactory(fetch, zxcvbnOptions)
zxcvbnOptions.addMatcher('pwned', matcherPwned)

const options = {
  // recommended
  dictionary: {
    ...zxcvbnCommonPackage.dictionary,
    ...zxcvbnEnPackage.dictionary
  },
  // recommended
  graphs: zxcvbnCommonPackage.adjacencyGraphs,
  // recommended
  useLevenshteinDistance: true,
  // optional
  translations: zxcvbnEnPackage.translations
}

zxcvbnOptions.setOptions(options)

export default {
  name: 'ResetPassword',
  metaInfo: {
    title: 'Reset Password'
  },
  components: {
    SnackBar
  },

  data: () => ({
    valid: false,
    password: '',
    confirmPassword: null,

    result: null,

    colorRank: 'error',
    txtRank: '',
    infoPW: false,
    valuePW: String,
    valueCPW: String,

    debounce,

    passwordRules: [
      v => !!v || 'Password is required',
      password => (password && password.length >= 10) || 'Your password must have at least 10 characters'
    ]
  }),
  watch: {
    password() {
      this.debounce()
    }
  },
  mounted() {
    this.debounce = debounce(this.useZxcvbn, 200)
    if (this.$route.query.token === undefined || this.$route.query.token.length < 220) {
      if (this.$store.state.auth.status.loggedIn) {
        this.$router.push({ name: 'Dashboard' })
      } else {
        this.$router.push({ name: 'Login' })
      }
    }
  },
  computed: {
    scoreProgress() {
      return this.result.score * 25
    },
    confirmPasswordRules() {
      return [
        v => !!v || 'Confirm Password is required',
        (this.password === this.confirmPassword) || 'Password must match'
      ]
    }
  },
  methods: {
    disableBtn() {
      if (this.password && this.result) {
        return !(this.password.length >= 10 && this.result.score >= 2 && this.password === this.confirmPassword)
      } else {
        return true
      }
    },
    setResult(result) {
      this.result = result
    },
    async useZxcvbn() {
      if (this.password) {
        this.result = await zxcvbnAsync(this.password)
      } else {
        this.result = null
      }
    },
    async resetPassword() {
      if (!this.$refs.form.validate()) {
        snackbarPlugin.showError('Please enter a valid password and confirm !')
      }
      if (this.$refs.form.validate()) {
        snackbarPlugin.showLoading('Updating ...')
        const res = await AuthService.confirmPasswordReset(this.password, this.$route.query.token)
        if (res.status === 200) {
          await this.$router.push('/')
          setTimeout(() => {
            snackbarPlugin.showSuccess('Your password has been reset')
          }, 1000)
        } else {
          await this.$router.push('/')
        }
      }
    }
  }
}
</script>

<style lang="scss">
.backgroundImg {
  background-image: url('../../assets/images/background-hex.png');
}
</style>
